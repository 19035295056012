<template>
  <div class="judge-page-design">
    <div class="student-box">
      <div style="margin: 40px 20px 30px 20px">
        <div class="student-item" v-for="item in studentList" :class="studentVal === item.student_id ? 'student-active' : ''" @click="changeStudent(item.student_id)">{{ item.nickname }}</div>
      </div>
    </div>
    <div class="view-box">
      <el-scrollbar style="height: 100%">
        <div @click="toView" style="height: 77px; display: flex; justify-content: flex-end; align-items: center; color: #1cb064; margin-right: 30px; cursor: pointer">
          <i class="iconfont">&#xe61e;</i>
          <span>预览</span>
        </div>
        <FullView :details="detailsInfo" :roleType="1" v-if="isShow"></FullView>
      </el-scrollbar>
    </div>
    <div class="score-box">
      <el-scrollbar style="height: 100%">
        <div style="margin: 40px 20px">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>评分区</div>
            <div style="font-size: 14px" @click="lookStandard"><i class="iconfont" style="color: #333; font-size: 20px">&#xe625;</i> 评分标准</div>
          </div>
          <div v-for="(item, index) in socreArr">
            <div style="margin: 30px 0 16px 0" v-if="item.keyScore">{{ keyVal[item.type] }}</div>
            <div style="margin-bottom: 36px; display: flex; justify-content: space-around; align-items: center" v-if="item.keyScore">
              <el-link type="primary" :underline="false" @click="fullMark(item)">满分</el-link>
              <div class="line"></div>
              <el-link type="primary" :underline="false" @click="zeroMark(item)">零分</el-link>
              <div class="line"></div>
              <el-input-number v-model="item.score" controls-position="right" :min="0" :max="item.keyScore" style="width: 100px"></el-input-number>
              <div>
                <span style="margin: 0 4px"> / </span>
                <span> {{ item.keyScore }} </span>
              </div>
            </div>
          </div>
          <el-button type="primary" style="width: 100%; margin-bottom: 24px" @click="ConfirmScore">确认评分</el-button>
          <el-button style="width: 100%; margin-left: 0" @click="nextStudent">下一个学生</el-button>
        </div>
      </el-scrollbar>
    </div>
    <el-drawer title="评分标准" :visible.sync="drawerVal" direction="rtl" :size="800">
      <div style="width: 100%; height: 100%; padding: 0 40px">
        <el-table border :data="scoreStandard" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '50px' }">
          <el-table-column prop="index" label="序号" width="60"> </el-table-column>
          <el-table-column prop="content" label="考核内容"> </el-table-column>
          <el-table-column prop="require" label="考核要点"> </el-table-column>
          <el-table-column prop="standard" label="评分标准"> </el-table-column>
          <el-table-column prop="score" label="配分" width="60"> </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { decorateStudentList, decorateStudentDetails, decorateJudgeScore } from "@/utils/newDecorate";
import FullView from "@/components/NewDecoratorFullScreen.vue";

export default {
  name: "UserPageDesign",
  components: {
    FullView,
  },
  props: {},
  data() {
    return {
      moduleObject: {},
      studentList: [],
      studentVal: void 0,
      detailsInfo: {},
      scoreStandard: [],
      isShow: false,
      keyVal: { 1: "店铺logo、店招", 2: "banner", 3: "商品主图", 4: "商品详情页", 5: "商品短视频" },
      socreArr: [],
      drawerVal: false,
    };
  },
  computed: {},
  methods: {
    zeroMark(data) {
      data.score = 0;
    },
    fullMark(data) {
      data.score = data.keyScore;
    },
    ConfirmScore() {
      let score_date = [];
      this.socreArr.map((item) => {
        if (item.keyScore) score_date.push({ score: item.score, type: item.type });
      });
      decorateJudgeScore({ exam_id: this.$route.query.examId, user_id: this.studentVal, score_date }).then(({ code, data, msg }) => {
        code === 200 ? this.$message.success(msg) : this.$message.warning(msg);
      });
    },
    nextStudent() {
      let index = this.studentList.findIndex((item) => this.studentVal === item.student_id);
      if (index === this.studentList.length - 1) return this.$message.warning("当前已经是最后一个学生！");
      this.studentVal = this.studentList[index + 1].student_id;
      this.getDetails();
    },
    getStudentList() {
      decorateStudentList({ exam_id: this.$route.query.examId }).then(({ data, msg }) => {
        this.moduleObject = data.score_arr;
        this.socreArr = [
          { score: void 0, type: 1, keyScore: this.moduleObject?.dz_score ? this.moduleObject.dz_score : false },
          { score: void 0, type: 2, keyScore: this.moduleObject?.poster_score ? this.moduleObject.poster_score : false },
          { score: void 0, type: 3, keyScore: this.moduleObject?.goods_img_score ? this.moduleObject.goods_img_score : false },
          { score: void 0, type: 4, keyScore: this.moduleObject?.goods_details_score ? this.moduleObject.goods_details_score : false },
          { score: void 0, type: 5, keyScore: this.moduleObject?.goods_short_video_score ? this.moduleObject.goods_short_video_score : false },
        ];
        this.scoreStandard = data.scoring_criteria;
        this.studentList = data.stu_list;
        this.studentVal = this.studentList[0].student_id;
        if (this.studentVal) this.getDetails();
      });
    },
    changeStudent(id) {
      this.studentVal = id;
      if (this.studentVal) this.getDetails();
    },
    getDetails() {
      this.isShow = false;
      decorateStudentDetails({ exam_id: this.$route.query.examId, user_id: this.studentVal }).then(({ data, msg }) => {
        this.detailsInfo = data;
        this.isShow = true;
        if (!this.detailsInfo.score_arr.lenth) {
          this.socreArr.map((data) => (data.score = void 0));
        }
        this.detailsInfo.score_arr.map((item) => {
          this.socreArr.map((data) => {
            if (item.type === data.type) data.score = item.score;
          });
        });
      });
    },
    toView() {
      let url = this.$router.resolve({ path: "/judges/newDecorator/fullScreen", query: { exam_id: this.$route.query.examId, user_id: this.studentVal } });
      window.open(url.href, "_blank");
    },
    lookStandard() {
      this.drawerVal = true;
    },
  },
  mounted() {
    this.getStudentList();
  },
};
</script>

<style scoped lang="scss">
.judge-page-design {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  .student-box {
    width: 216px;
    border-right: 1px solid #d9d9d9;
    .student-item {
      height: 40px;
      line-height: 40px;
      background: #fafafa;
      color: #666;
      padding: 0 12px;
      margin-bottom: 10px;
      border-radius: 4px;
    }
    .student-active {
      background: #1cb064;
      color: #fff;
    }
  }
  .view-box {
    flex: 1;
  }
  .score-box {
    width: 304px;
    border-left: 1px solid #d9d9d9;
    color: #727272;
    font-size: 16px;
    .line {
      width: 1px;
      height: 12px;
      background: #d9d9d9;
    }
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
